import "../css/bookings.scss";

import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button, InputGroup, NonIdealState, Spinner, Text } from "@blueprintjs/core";
import config from "../components/config";
import { Page } from "./page";
import { formatLabel } from "../utils/utils";
import { withRouter } from "react-router";

class BookingsPageComponent extends Page {
  columnNames = [
    "Id",
    "Ref",
    "Date",
    "Email",
    "Phone",
    "Passengers",
    "Payment",
    "Ticket",
    "Profit",
  ];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,
      loading: true,
      unticketed: false,
      reload: false,
      loaded: false,

      sorted: [],
      filters: {},
      pageSize: 100,
      page: 0,
    };
  }

  getPageName() {
    return "BookingsPage";
  }

  componentDidMount() {
    this.fetchData(this.state);
  }

  startFilter = () => {
    this.setState({ page: 0 });
    this.fetchData(this.state);
  };

  requestData = (pageSize, page, sorted, filters) => {
    return new Promise((resolve, reject) => {
      let orderBy = "id";
      let orderDesc = true;
      if (sorted.length > 0) {
        orderBy = sorted[0].id;
        orderDesc = sorted[0].desc;
      }

      return axios
        .get(`${config.apiHost}bookings`, {
          params: {
            orderBy: orderBy,
            orderDesc: orderDesc,
            pageSize: pageSize,
            filter: JSON.stringify(filters),
            page: page,
            unticketed: this.state.unticketed,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          const errorMessage = this.handleAxiosCatch(error);

          return reject(errorMessage);
        });
    });
  };

  fetchData(state) {
    this.setState({ loading: true });

    this.requestData(state.pageSize, state.page, state.sorted, state.filters)
      .then((res) => {
        this.setState({
          data: res.rows,
          pages: res.pages,
          loading: false,
          loaded: true,
        });
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);

        this.setState({
          error: errorMessage,
          loading: false,
          loaded: false,
          data: [],
          pages: 0,
        });
      });
  }

  handleFilterChange = (name, e) => {
    const filterValue = e.target.value;
    this.setState((oldState) => {
      const filters = oldState.filters;
      filters[name] = filterValue;
      return {
        filters: filters,
      };
    });
  };

  renderAuth = () => {
    const { data, pages, loading, reload, loaded, filters, page } = this.state;

    if (!loaded)
      return (
        <div>
          <h1 className="header">Bookings</h1>
          <NonIdealState>
            <Spinner />
            <h4 className="bp3-heading">Results are being loaded</h4>
            <div>Please wait, we are loading the bookings list</div>
          </NonIdealState>
        </div>
      );

    return (
      <div>
        <h1 className="header">Bookings</h1>
        <p style={{ marginBottom: "30px" }}>
          Showing page <strong>{page + 1}</strong> of <strong>{pages}</strong>
        </p>

        <table className="bookings-table bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-html-table-condensed">
          <thead>
            <tr className="column-names">
              {this.columnNames.map((name) => {
                return <th>{name}</th>;
              })}
            </tr>
            <tr className="column-filters">
              <th>
                <InputGroup
                  onChange={(e) => this.handleFilterChange("id", e)}
                  small
                  value={filters.id || ""}
                  onKeyPress={() => this.startFilter()}
                />
              </th>
              <th>
                <InputGroup
                  onChange={(e) => this.handleFilterChange("number", e)}
                  small
                  value={filters.number || ""}
                  onKeyPress={() => this.startFilter()}
                />
              </th>
              <th />
              <th>
                <InputGroup
                  onChange={(e) => this.handleFilterChange("email", e)}
                  small
                  value={filters.email || ""}
                  onKeyPress={() => this.startFilter()}
                />
              </th>
              <th>
                <InputGroup
                  onChange={(e) => this.handleFilterChange("phone", e)}
                  small
                  value={filters.phone || ""}
                  onKeyPress={() => this.startFilter()}
                />
              </th>
              <th>
                <InputGroup
                  onChange={(e) => this.handleFilterChange("passengers", e)}
                  small
                  value={filters.passengers || ""}
                  onKeyPress={() => this.startFilter()}
                />
              </th>
              <th />
              <th />
              <th>{loading ? <Spinner size={20} /> : <div />}</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan={9}>
                  <h5 className="has-text-centered">No results</h5>
                </td>
              </tr>
            ) : (
              data.map((row) => (
                <tr>
                  <td>{row.id}</td>
                  <td>
                    <Link to={`/booking/id-${row.id}`}>
                      <strong>{row.number}</strong>
                    </Link>
                  </td>
                  <td className="created-at">{row.createdAt}</td>
                  <td>
                    <Text ellipsize>{row.email}</Text>
                  </td>
                  <td>{row.phone}</td>
                  <td className="passengers">{row.passengers}</td>
                  <td>{formatLabel(row.paymentStatus)}</td>
                  <td>{formatLabel(row.status)}</td>
                  <td>{row.profit}</td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr>
              <td>
                {page > 0 ? (
                  <Button
                    icon="chevron-left"
                    small
                    onClick={() => {
                      this.setState({ page: page - 1 });
                      this.fetchData(this.state);
                    }}
                  >
                    Previous
                  </Button>
                ) : (
                  <div />
                )}
              </td>
              <td className="has-text-centered" colSpan={7}>
                <div className="pages">
                  Page <strong>{page + 1}</strong> of <strong>{pages}</strong>
                </div>
              </td>
              <td>
                {page < pages ? (
                  <Button
                    rightIcon="chevron-right"
                    small
                    onClick={() => {
                      this.setState({ page: page + 1 });
                      this.fetchData(this.state);
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <div />
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  changeUnticketed() {
    this.setState({
      loading: true,
      reload: true,
      unticketed: !this.state.unticketed,
    });
  }
}

export const BookingsPage = withRouter(BookingsPageComponent);
