import { Intent, IToaster, Position, Toaster } from "@blueprintjs/core";
import { isLoggedIn } from "../utils/auth";
import MyComponent from "../components/my_component";
import { TopMenu } from "../components/menu";

class PageComponent extends MyComponent {
  _toaster: IToaster | null = null;

  getToaster() {
    if (this._toaster == null) {
      this._toaster = Toaster.create({
        position: Position.TOP,
      });
    }

    return this._toaster;
  }

  selfAlert(text: string, intent = Intent.PRIMARY) {
    this.getToaster().show({ message: text, intent: intent });
  }

  getPageName() {
    return this.constructor.name;
  }

  renderAuth() {
    return "";
  }

  render() {
    if (!isLoggedIn()) this.props.history.push("/");

    return (
      <div className={this.getPageName()}>
        <TopMenu />
        <section className="section main-section">
          <div className="container"> {this.renderAuth()} </div>
        </section>
      </div>
    );
  }
}

export const Page = PageComponent;
