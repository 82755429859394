import React, { cloneElement, Component } from "react";
import { Classes, Dialog } from "@blueprintjs/core";

class ControlledModal extends Component {
  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  okButtonClick = () => {
    if (!this.props.okAction || this.props.okAction()) this.handleClose();
  };

  render() {
    const btn = this.props.button;

    return (
      <div>
        {cloneElement(btn, {
          onClick: this.handleOpen,
        })}
        <Dialog
          onClose={this.handleClose}
          title={this.props.title}
          isOpen={this.state.open}
          {...this.props.dialogProps}
        >
          <div className={Classes.DIALOG_BODY}>
            {this.props.children}
            {this.props.okButton &&
              cloneElement(this.props.okButton, {
                onClick: this.okButtonClick,
              })}
          </div>
        </Dialog>
      </div>
    );
  }
}

ControlledModal.defaultProps = {
  button: null,
  okButton: null,
  okAction: null,
  title: "",
  dialogProps: {},
};

export default ControlledModal;
