import "../css/tasks.scss";

import React from "react";
import axios from "axios";
import { Button, EditableText, InputGroup, NonIdealState, Spinner, TextArea } from "@blueprintjs/core";
import moment from "moment";
import { Page } from "./page";
import config from "../components/config";
import { formatLabel } from "../utils/utils";
import ControlledModal from "../components/controlled_modal";
import { withRouter } from "react-router";

class TasksPageComponent extends Page {
  columnNames = ["Id", "Task Name", "Period", "Status", "Last run", ""];

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pages: null,

      loading: true,
      loaded: false,

      sorted: [
        {
          id: "id",
          desc: false,
        },
      ],
      filters: {},
      pageSize: 100,
      page: 0,
    };
  }

  getPageName = () => {
    return "TasksPage";
  };

  componentDidMount = () => {
    this.fetchData(this.state);
  };

  startFilter = () => {
    this.setState({ page: 0 });
    this.fetchData(this.state);
  };

  requestData = (pageSize, page, sorted, filters) => {
    return new Promise((resolve, reject) => {
      let orderBy = "id";
      let orderDesc = true;
      if (sorted.length > 0) {
        orderBy = sorted[0].id;
        orderDesc = sorted[0].desc;
      }

      return axios
        .get(`${config.apiHost}tasks`, {
          params: {
            orderBy: orderBy,
            orderDesc: orderDesc,
            pageSize: pageSize,
            filter: JSON.stringify(filters),
            page: page,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          const errorMessage = this.handleAxiosCatch(error);

          return reject(errorMessage);
        });
    });
  };

  fetchData = (state) => {
    this.setState({ loading: true });

    this.requestData(state.pageSize, state.page, state.sorted, state.filters)
      .then((res) => {
        this.setState({
          data: res.rows,
          pages: res.pages,
          loading: false,
          loaded: true,
        });
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);

        this.setState({
          error: errorMessage,
          loading: false,
          loaded: false,
          data: [],
          pages: 0,
        });
      });
  };

  handleFilterChange = (name, e, x) => {
    const filterValue = e.target.value;
    const filters = this.state.filters;
    filters[name] = filterValue;

    this.setState({ filters: filters });
  };

  renderAuth = () => {
    const { data, pages, loading, loaded, filters, page } = this.state;

    if (!loaded)
      return (
        <div>
          <h1 className="header">Tasks</h1>
          <NonIdealState>
            <Spinner />
            <h4 className="bp3-heading">Results are being loaded</h4>
            <div>Please wait, we are loading the tasks list</div>
          </NonIdealState>
        </div>
      );

    return (
      <div>
        <h1 className="header">Tasks</h1>
        <p style={{ marginBottom: "30px" }}>
          Showing page <strong>{page + 1}</strong> of <strong>{pages}</strong>
        </p>

        <table className="tasks-table bp3-html-table bp3-html-table-bordered bp3-html-table-striped bp3-html-table-condensed">
          <thead>
            <tr className="column-names">
              {this.columnNames.map((name) => {
                return <th>{name}</th>;
              })}
            </tr>
            <tr className="column-filters">
              <th>
                <InputGroup
                  onChange={() => this.handleFilterChange("id")}
                  small
                  value={filters.id || ""}
                  onKeyPress={() => this.startFilter()}
                />
              </th>
              <th>
                <InputGroup
                  onChange={() => this.handleFilterChange("task")}
                  small
                  value={filters.task || ""}
                  onKeyPress={() => this.startFilter()}
                />
              </th>
              <th />
              <th />
              <th />
              <th>{loading ? <Spinner size={20} /> : <div />}</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan={6}>
                  <h5 className="has-text-centered">No results</h5>
                </td>
              </tr>
            ) : (
              data.map((row, index) => (
                <tr>
                  <td>{row.id}</td>
                  <td>
                    <b>{row.task}</b>
                  </td>
                  <td>
                    <EditableText
                      value={row.period}
                      onChange={(v) => this.setStateValue(`data.${index}.period`, v)}
                      onConfirm={(v) => this.changeTask(row.id, { period: v })}
                    />
                  </td>
                  <td>
                    {formatLabel(row.status)}{" "}
                    <Button
                      small
                      onClick={() =>
                        this.changeTask(row.id, {
                          status: row.status === "active" ? "disabled" : "active",
                        })
                      }
                    >
                      {row.status === "active" ? "Disable" : "Enable"}
                    </Button>
                  </td>
                  <td>
                    {row.lastRun ? (
                      <>
                        {moment(row.lastRun.launchTime).from(row.lastRun.now)}
                        <br />
                        <b>{row.lastRun.status}</b>
                        <br />
                        Progress: {row.lastRun.progress}
                        <br />
                        <ControlledModal
                          button={<Button small>Output</Button>}
                          title="Task output"
                          dialogProps={{ className: "task-output" }}
                        >
                          <TextArea fill value={row.lastRun.output} readOnly />
                        </ControlledModal>
                      </>
                    ) : (
                      "Never launched"
                    )}
                  </td>
                  <td>
                    {row.shouldRun ? (
                      <b>Launch trigger</b>
                    ) : (
                      <Button small onClick={() => this.changeTask(row.id, { shouldRun: 1 })}>
                        Launch
                      </Button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr>
              <td>
                {page > 0 ? (
                  <Button
                    icon="chevron-left"
                    small
                    onClick={() => {
                      this.setState({ page: page - 1 });
                      this.fetchData(this.state);
                    }}
                  >
                    Previous
                  </Button>
                ) : (
                  <div />
                )}
              </td>
              <td className="has-text-centered" colSpan={4}>
                <div className="pages">
                  Page <strong>{page + 1}</strong> of <strong>{pages}</strong>
                </div>
              </td>
              <td>
                {page < pages ? (
                  <Button
                    rightIcon="chevron-right"
                    small
                    onClick={() => {
                      this.setState({ page: page + 1 });
                      this.fetchData(this.state);
                    }}
                  >
                    Next
                  </Button>
                ) : (
                  <div />
                )}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  };

  changeTask = (id, params) => {
    this.setState({ loading: true });

    return axios
      .post(`${config.apiHost}tasks/${id}/change`, params)
      .then(() => {
        this.fetchData(this.state);
        this.selfAlert("Task changed");
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);
        this.fetchData(this.state);
      });
  };
}

export const TasksPage = withRouter(TasksPageComponent);
