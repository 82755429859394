import React from "react";
import { Tag } from "@blueprintjs/core";
import moment from "moment";

export function formatLabel(label) {
  let color = "grey";

  switch (label) {
    case "issued":
      color = "success";
      break;
    case "waiting":
    case "unknown":
      color = "warning";
      break;
    case "failed":
    case "totally_failed":
      color = "danger";
      break;

    case "completed":
      color = "success";
      break;
    case "started":
      color = "warning";
      break;
    case "error":
    case "dead":
      color = "danger";
      break;

    // Cards
    case "valid":
      color = "success";
      break;
    case "cancelled":
      color = "danger";
      break;

    // Transactions
    case "authorized":
    case "charged":
      color = "success";
      break;
    case "refunded":
    case "declined":
      color = "danger";
      break;

    // Antifraud
    case "accept":
      color = "success";
      break;
    case "decline":
      color = "danger";
      break;

    // Email
    case "opened":
      color = "success";
      break;

    // SMS
    case "delivered":
      color = "success";
      break;

    // Tasks
    case "active":
      color = "success";
      break;
    default:
      return label;
  }

  return <Tag intent={color}>{label}</Tag>;
}

export function formatDate(date) {
  try {
    return moment(date).format("D MMM YYYY");
  } catch (e) {
    return date;
  }
}

export function nl2br(html) {
  if (!html) return "";

  return html.split("\n").map((item, key) => (
    <span key={key}>
      {item}
      <br />
    </span>
  ));
}
