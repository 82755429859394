class Config {
  currencies = ["EUR", "USD", "UAH", "RUB"];

  apiHost = Config.isDev() ? "http://localhost:8090/admin/" : "https://api.jetcompass.com/admin/";

  webHost = Config.isDev() ? "http://localhost:3000/" : "https://admin.jetcompass.com/";

  websiteHost = "https://jetcompass.com/";

  dev = Config.isDev();

  static isDev() {
    return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
  }
}

const config = new Config();
window.config = config;

export default config;
