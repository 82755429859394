import "../css/booking.scss";

import React from "react";
import axios from "axios";
import {
  Breadcrumbs,
  Button,
  Callout,
  EditableText,
  HTMLSelect,
  InputGroup,
  Intent,
  Menu,
  MenuDivider,
  MenuItem,
  NonIdealState,
  Popover,
  Spinner,
  Tab,
  Tabs,
  TextArea,
} from "@blueprintjs/core";
import ReactJson from "react-json-view";
import { Page } from "./page";
import config from "../components/config";
import { formatDate, formatLabel, nl2br } from "../utils/utils";
import AddPaymentModal from "../components/booking/add_payment_modal";
import ControlledModal from "../components/controlled_modal";
import RefundPaymentModal from "../components/booking/refund_payment_modal";
import { withRouter } from "react-router";

class BookingPageComponent extends Page {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loaded: false,
      error: null,
      data: null,
      pnrs: [],
      pnrsLoading: [],
      loadingPayments: {},

      paxEditMode: false,
      paxData: null,

      mainEditMode: false,
    };
    this.loadData();
  }

  getId() {
    const { id } = this.props.match.params;
    if (id.substr(0, 3) === "id-") return id.substr(3);

    return id;
  }

  getNumber() {
    return this.state?.data?.number ?? this.getId();
  }

  getPageName() {
    return "BookingPage";
  }

  loadData() {
    this.setState({ loading: true });

    return axios
      .get(`${config.apiHost}booking`, {
        params: {
          id: this.getId(),
        },
      })
      .then((response) => {
        if (response?.data?.error)
          return this.setState({
            error: response.data.error,
            loading: false,
            data: null,
            loaded: true,
          });

        return this.setState({
          error: null,
          loading: false,
          data: response.data.data,
          loaded: true,
        });
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);

        this.setState({
          error: errorMessage,
          loaded: true,
          loading: false,
        });
      });
  }

  bookingAction = (name, params = {}) => {
    this.setState({
      loading: true,
    });

    return axios
      .get(`${config.apiHost}booking/${this.getId()}/${name}`, {
        params,
      })
      .then(() => {
        this.loadData();
        this.selfAlert("Success");
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);
        this.loadData();
      });
  };

  issueBooking = () => {
    return this.bookingAction("issue");
  };

  markBookingFraudlent = () => {
    return this.bookingAction("fraud");
  };

  refetchBooking = () => {
    return this.bookingAction("refetch");
  };

  emailTickets = (locale) => {
    return this.bookingAction("send-tickets", { locale: locale || this.state.data.info.locale });
  };

  renderAuth() {
    const bItems = [
      { text: "Home" },
      { href: "/bookings/", text: "Bookings" },
      { text: this.getNumber() },
    ];

    const { error, loaded } = this.state;

    if (!loaded)
      return (
        <div>
          <Breadcrumbs items={bItems} />
          <NonIdealState>
            <Spinner />
            <h4 className="bp3-heading">Results are being loaded</h4>
            <div>Please wait, we are loading the booking details</div>
          </NonIdealState>
        </div>
      );

    if (error)
      return (
        <div>
          <Breadcrumbs items={bItems} />
          <Callout intent="danger" icon="error" title="Error loading booking">
            <p>{error}</p>
          </Callout>
        </div>
      );

    window.document.title = this.getNumber();

    return (
      <div>
        <Breadcrumbs items={bItems} />
        {this.state.loading && (
          <div className="toolbar">
            <Spinner size={20} />
          </div>
        )}
        <div className="toolbar">
          <Button icon="reset" onClick={this.refetchBooking}>
            Refetch
          </Button>
          {this.state.data.info.ticketStatus !== "issued" &&
            this.state.data.payments.some(
              (p) => p.status === "charged" || p.status === "authorized",
            ) && (
              <>
                <Button intent={Intent.SUCCESS} onClick={this.issueBooking}>
                  Issue
                </Button>
                <Button intent={Intent.DANGER} icon="disable" onClick={this.markBookingFraudlent}>
                  FRAUD BOOKING
                </Button>
              </>
            )}
          {this.state.data.info.ticketStatus === "issued" && (
            <>
              <Popover
                content={
                  <Menu>
                    <MenuItem text="Send" onClick={() => this.emailTickets} />
                    <MenuDivider />
                    <MenuItem text="EN" onClick={() => this.emailTickets("EN")} />
                    <MenuItem text="RU" onClick={() => this.emailTickets("RU")} />
                    <MenuItem text="DE" onClick={() => this.emailTickets("DE")} />
                    <MenuDivider />
                    <MenuItem
                      text="Show Web (EN)"
                      href={`${config.apiHost}booking/${this.getId()}/tickets/html?locale=en&key=${
                        this.state.data.info.key
                      }`}
                    />
                    <MenuItem
                      text="Show Web (RU)"
                      href={`${config.apiHost}booking/${this.getId()}/tickets/html?locale=ru&key=${
                        this.state.data.info.key
                      }`}
                    />
                    <MenuItem
                      text="Show Web (DE)"
                      href={`${config.apiHost}booking/${this.getId()}/tickets/html?locale=de&key=${
                        this.state.data.info.key
                      }`}
                    />
                    <MenuDivider />
                    <MenuItem
                      text="Show PDF (EN)"
                      href={`${config.apiHost}booking/${this.getId()}/tickets/pdf?locale=en&key=${
                        this.state.data.info.key
                      }`}
                    />
                    <MenuItem
                      text="Show PDF (RU)"
                      href={`${config.apiHost}booking/${this.getId()}/tickets/pdf?locale=ru&key=${
                        this.state.data.info.key
                      }`}
                    />
                    <MenuItem
                      text="Show PDF (DE)"
                      href={`${config.apiHost}booking/${this.getId()}/tickets/pdf?locale=de&key=${
                        this.state.data.info.key
                      }`}
                    />
                  </Menu>
                }
              >
                <Button rightIcon="caret-down" icon="envelope" text="Send tickets" />
              </Popover>
              {this.state.data.info.ticketCode && (
                <InputGroup readOnly value={`https://jcmp.io/${this.state.data.info.ticketCode}`} />
              )}
            </>
          )}
        </div>
        <div className="columns">
          <div className="column">{this.renderMainInfo()}</div>
          <div className="column column-2x">
            {this.renderPrices()}
            {this.renderPartner()}
          </div>
        </div>
        {this.renderPassengers()}
        {this.renderPayments()}
        {this.renderFlights()}
        {this.renderPnrs()}
        {this.renderEmails()}
        {this.renderSms()}
        {this.renderLog()}
      </div>
    );
  }

  saveMainData = () => {
    const data = {
      locale: this.state.data.info.locale,
      phone: this.state.data.info.phone,
      email: this.state.data.info.email,
    };

    this.setState({ loading: true });

    return axios
      .post(`${config.apiHost}booking/${this.getId()}/main-data`, data)
      .then(() => {
        this.setState({ mainEditMode: false });
        this.selfAlert("Main data saved");
        this.loadData();
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);
      });
  };

  renderMainInfo() {
    return (
      <div className="panel panel-danger">
        <div className="panel-heading">
          <div>Booking details</div>
          {this.state.mainEditMode ? (
            <Button
              disabled={this.state.loading}
              small
              intent={Intent.PRIMARY}
              onClick={this.saveMainData}
            >
              Save
            </Button>
          ) : (
            <Button
              disabled={this.state.loading}
              small
              onClick={() => {
                this.setState({ mainEditMode: true });
              }}
            >
              Edit
            </Button>
          )}
        </div>
        <div className="panel-body">
          <dl className="dl-horizontal">
            <dt>Booking id</dt>
            <dd>{this.state.data.id}</dd>
            <dt>Booking reference</dt>
            <dd>{this.state.data.number}</dd>
            <dt>Email</dt>
            <dd>
              {this.state.mainEditMode ? (
                <EditableText
                  value={this.state.data.info.email}
                  onChange={(v) => {
                    this.setStateValue("data.info.email", v);
                  }}
                />
              ) : (
                this.state.data.info.email
              )}
            </dd>
            <dt>Phone</dt>
            <dd>
              {this.state.mainEditMode ? (
                <EditableText
                  value={this.state.data.info.phone}
                  onChange={(v) => {
                    this.setStateValue("data.info.phone", v);
                  }}
                />
              ) : (
                this.state.data.info.phone
              )}
            </dd>
            <dt>Date</dt>
            <dd>{this.state.data.createdAt}</dd>
            <dt>Locale</dt>
            <dd>
              {this.state.mainEditMode ? (
                <HTMLSelect
                  options={["RU", "EN", "DE", "FR", "ES"]}
                  value={this.state.data.info.locale}
                  onChange={(e) => {
                    this.setStateValue("data.info.locale", e.target.value);
                  }}
                />
              ) : (
                this.state.data.info.locale
              )}
            </dd>
            <dt>Luggage</dt>
            <dd>{this.state.data.info.luggage ? <b>Added</b> : "no"}</dd>
            <dt>Payment status</dt>
            <dd>{formatLabel(this.state.data.info.paymentStatus)}</dd>
            <dt>Ticketing status</dt>
            <dd>{formatLabel(this.state.data.info.ticketStatus)}</dd>
            <dt>Antifraud decision</dt>
            <dd>{formatLabel(this.state.data.info.antifraud)}</dd>
            <dt>Booking hash</dt>
            <dd>
              <span style={{ fontSize: "11px" }}>{this.state.data.info.bookingHash}</span>
            </dd>
            <dt>Click</dt>
            <dd>
              {this.state.data.info.clickId} ({this.state.data.info.clickDetails})
            </dd>
            <dt>Profit</dt>
            <dd>{this.state.data.info.profit}</dd>
            <dt>Old panel</dt>
            <dd>
              <a
                href={`https://admin.jetcompass.com/orders/view?id=${this.state.data.id}`}
                target="_blank"
              >
                Open
              </a>
            </dd>
          </dl>
        </div>
      </div>
    );
  }

  renderPrices() {
    return (
      <div className="panel panel-success">
        <div className="panel-heading">Price details</div>
        <table className="table table-condensed">
          <thead>
            <tr>
              <th />
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.pnrs.map((pnr) => (
              <tr className="price-small">
                <td>
                  <strong>{pnr.locator}</strong>
                  <br />
                  {pnr.supplier}
                </td>
                <td>{pnr.price} EUR</td>
              </tr>
            ))}
            <tr className="price-small">
              <td>Total</td>
              <td>
                {this.state.data.pnrs.reduce((accumulator, pnr) => accumulator + pnr.price, 0)} EUR
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderPartner() {
    return (
      <div className="panel panel-default">
        <div className="panel-heading">Partner</div>
        <table className="table table-condesed">
          <thead>
            <tr>
              <th>Partner</th>
              <th>Marker</th>
              <th>Profit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.data.partner.id}</td>
              <td>{this.state.data.partner.marker}</td>
              <td>
                {this.state.data.partner.profit} {this.state.data.partner.profitCurrency}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  changePaxField = (paxId, fieldName, fieldValue) => {
    const { paxData } = this.state;
    paxData[paxId][fieldName] = fieldValue;
    this.setState({ paxData });
  };

  savePaxData = () => {
    this.setState({ loading: true });

    return axios
      .post(`${config.apiHost}booking/${this.getId()}/pax-data`, this.state.paxData)
      .then(() => {
        this.setState({ paxEditMode: false });
        this.selfAlert("Pax data saved");
        this.loadData();
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);
      });
  };

  renderPassengers() {
    return (
      <div className="panel panel-warning">
        <div className="panel-heading">
          <div>Passengers</div>
          {this.state.paxEditMode ? (
            <Button
              disabled={this.state.loading}
              small
              intent={Intent.PRIMARY}
              onClick={this.savePaxData}
            >
              Save
            </Button>
          ) : (
            <Button
              disabled={this.state.loading}
              small
              onClick={() => {
                this.setState({ paxEditMode: true, paxData: this.state.data.passengers });
              }}
            >
              Edit
            </Button>
          )}
        </div>
        <table className="table">
          <tbody>
            <tr>
              <th>Type</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Father's name</th>
              <th>DOB</th>
              <th>Nationality</th>
              <th>Passport</th>
              <th>Tickets</th>
            </tr>
            {this.state.paxEditMode &&
              this.state.paxData.map((p, index) => (
                <tr>
                  <td>
                    {p.type === "C" ? "Child" : p.type === "I" ? "Infant" : "Adult"}
                    <br />
                    <HTMLSelect
                      options={["M", "F"]}
                      value={p.sex}
                      onChange={(e) => {
                        this.changePaxField(index, "sex", e.target.value);
                      }}
                    />
                  </td>
                  <td>
                    <EditableText
                      value={p.firstName}
                      onChange={(v) => {
                        this.changePaxField(index, "firstName", v);
                      }}
                    />
                  </td>
                  <td>
                    <EditableText
                      value={p.lastName}
                      onChange={(v) => {
                        this.changePaxField(index, "lastName", v);
                      }}
                    />
                  </td>
                  <td>
                    <EditableText
                      value={p.fName}
                      onChange={(v) => {
                        this.changePaxField(index, "fName", v);
                      }}
                    />
                  </td>
                  <td>
                    <EditableText
                      value={p.dateOfBirth}
                      onChange={(v) => {
                        this.changePaxField(index, "dateOfBirth", v);
                      }}
                    />
                  </td>
                  <td>
                    <EditableText
                      value={p.nationality}
                      onChange={(v) => {
                        this.changePaxField(index, "nationality", v);
                      }}
                    />
                  </td>
                  <td>
                    <EditableText
                      value={p.passportNumber}
                      onChange={(v) => {
                        this.changePaxField(index, "passportNumber", v);
                      }}
                    />{" "}
                    <br />
                    valid till{" "}
                    <EditableText
                      value={p.passportExpirationDate}
                      onChange={(v) => {
                        this.changePaxField(index, "passportExpirationDate", v);
                      }}
                    />
                  </td>
                  <td />
                </tr>
              ))}

            {!this.state.paxEditMode &&
              this.state.data.passengers.map((p) => (
                <tr>
                  <td>
                    {p.type === "C" ? "Child" : p.type === "I" ? "Infant" : "Adult"}
                    <br />
                    {p.sex === "M" ? "Male" : "Female"}
                  </td>
                  <td>{p.firstName}</td>
                  <td>{p.lastName}</td>
                  <td>{p.fName}</td>
                  <td>{formatDate(p.dateOfBirth)}</td>
                  <td>{p.nationality}</td>
                  <td>
                    {p.passportNumber} <br />
                    valid till {formatDate(p.passportExpirationDate)}
                  </td>
                  <td />
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }

  addPayment = (amount, currency, description) => {
    this.setState({ loading: true });

    return axios
      .post(`${config.apiHost}booking/${this.getId()}/add-payment`, {
        amount: amount,
        currency: currency,
        description: description,
      })
      .then(() => {
        this.loadData();
        this.selfAlert("Payment added");
      })
      .catch((error) => {
        this.handleAxiosCatch(error);
        this.loadData();
      });
  };

  paymentRefund = (id, amount, currency, description) => {
    this.setState({ loading: true });

    return axios
      .post(`${config.apiHost}payment/${id}/refund`, {
        amount: amount,
        currency: currency,
        description: description,
      })
      .then(() => {
        this.loadData();
        this.selfAlert("Payment refunded");
      })
      .catch((error) => {
        this.handleAxiosCatch(error);
        this.loadData();
      });
  };

  renderPayments = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-success">
            <div className="panel-heading">
              <div>Payments</div>
              <AddPaymentModal onAdd={this.addPayment} />
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <th width="100">Id, type</th>
                  <th>Provider</th>
                  <th width="60">Method</th>
                  <th>Details</th>
                  <th width="240">Details 2</th>
                  <th width="90">Created at</th>
                  <th width="80">Paid at</th>
                  <th width="140">Amount</th>
                  <th>Profit</th>
                  <th>Score</th>
                  <th>Status</th>
                  <th width="100" />
                </tr>
                {this.state.data.payments.map((p) => (
                  <tr>
                    <td>
                      {p.id}
                      <br />
                      <small>
                        <nobr>
                          {p.type}, {p.purpose}
                        </nobr>
                      </small>
                      <br />
                      <small>{p.number}</small>
                    </td>
                    <td>
                      {p.provider}
                      <br />
                      <small style={{ fontSize: "8px" }}>{p.providerId}</small>
                    </td>
                    <td>{p.method}</td>
                    <td>
                      <div style={{ fontSize: "10px" }}>
                        {p.paymentDetails === "" ? "" : nl2br(p.paymentDetails)}
                        {p.card == null ? (
                          ""
                        ) : (
                          <span>
                            Number: {p.card.number}
                            <br />
                            Cardholder: <nobr>{p.card.cardholder}</nobr>
                            <br />
                            Exp: {p.card.expiration}
                            <br />
                            Type: {p.card.type}
                            <br />
                            Bank: {p.card.bank} ({p.card.country})
                          </span>
                        )}
                      </div>
                    </td>
                    <td style={{ fontSize: "9px" }}>
                      {nl2br(p.otherDetails)} <b>{p.description ? p.description : ""}</b>
                    </td>
                    <td style={{ fontSize: "10px" }}>{p.createdAt}</td>
                    <td style={{ fontSize: "10px" }}>{p.paidAt}</td>
                    <td style={{ fontSize: "10px" }}>
                      Original:
                      <nobr>
                        <strong> {p.amount} EUR</strong>
                      </nobr>
                      <br />
                      Fixed:
                      <nobr>
                        <strong>
                          {" "}
                          {p.fixedAmount} {p.fixedCurrency}
                        </strong>
                      </nobr>
                      <br />
                      Charged:
                      <nobr>
                        {" "}
                        {p.chargedAmount} {p.chargedCurrency}
                      </nobr>
                    </td>
                    <td style={{ fontSize: "10px" }}>
                      {p.profit} {p.profitCurrency}
                    </td>
                    <td style={{ fontSize: "10px" }}>
                      <nobr>3DS: {p.is3ds ? "true" : "false"}</nobr>
                      <br />
                      Secure: {p.secure ? "true" : "false"} <br />
                      Score: <strong>{p.score}</strong>
                    </td>
                    <td>{formatLabel(p.status)}</td>
                    <td>
                      {p.provider ? (
                        <>
                          <Button
                            disabled={this.state.loading}
                            small
                            onClick={() => this.paymentAction(p.id, "reload")}
                          >
                            Get&nbsp;status
                          </Button>
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      {p.status === "authorized" ? (
                        <>
                          {" "}
                          <Button
                            disabled={this.state.loading}
                            small
                            onClick={() => this.paymentAction(p.id, "charge")}
                          >
                            Charge
                          </Button>
                          <br />
                          <Button
                            disabled={this.state.loading}
                            small
                            onClick={() => this.paymentAction(p.id, "void")}
                          >
                            Void
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}

                      {p.status === "charged" ? (
                        <RefundPaymentModal
                          onRefund={this.paymentRefund}
                          amountTotal={`${p.chargedAmount} ${p.chargedCurrency}`}
                          paymentId={p.id}
                        />
                      ) : (
                        <></>
                      )}

                      {p.status === "new" || p.status === "pending" ? (
                        <>
                          <a
                            href={`${config.websiteHost}payment/?paymentId=${p.id}&hash=${p.hash}`}
                            target="_blank"
                          >
                            Pay
                          </a>
                          <br />
                          <Button
                            disabled={this.state.loading}
                            small
                            onClick={() => this.paymentAction(p.id, "send-email")}
                          >
                            Send link
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  paymentAction = (paymentId, action) => {
    this.setState({
      loading: true,
    });

    return axios
      .get(`${config.apiHost}payment/${paymentId}/${action}`)
      .then(() => {
        this.loadData();
        this.selfAlert("Success");
      })
      .catch((error) => {
        this.handleAxiosCatch(error);
        this.loadData();
      });
  };

  renderFlights = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel panel-info">
            <div className="panel-heading">
              <div>Flights</div>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <th>Flight</th>
                  <th>Departure</th>
                  <th>Arrival</th>
                  <th>Class</th>
                  <th>Segment</th>
                </tr>
                {this.state.data.pnrs.map((p) => {
                  return (
                    <>
                      <tr className="pnr-row">
                        <td colSpan={3}>
                          Id: {p.id} / Farecloud id:{" "}
                          <a
                            target="blank"
                            href={`https://admin.farecloud.io/booking/ref${p.farecloudId}`}
                          >
                            {p.farecloudId}
                          </a>
                          <br />
                          Supplier: {p.supplier} / Locator: <b>{p.locator}</b> / Status:{" "}
                          {formatLabel(p.status)}
                        </td>
                        <td colSpan={2} style={{ textAlign: "right" }}>
                          {!p.refund ? (
                            ""
                          ) : (
                            <div>
                              <b>Id:</b> {p.refund.id} // <strong>{p.refund.refundToBePaid}</strong>{" "}
                              of {p.refund.amount} EUR
                              <br />
                              <b>CR:</b> {p.refund.customerRequested ? "Y" : "N"},<b> Req:</b>{" "}
                              {p.refund.refundRequested ? "Y" : "N"},<b> Rec:</b>{" "}
                              {p.refund.refundReceived ? "Y" : "N"},<b> Paid:</b>{" "}
                              {p.refund.refundMade
                                ? "Yes"
                                : p.refund.altRefund
                                ? "Alternative"
                                : "No"}
                            </div>
                          )}
                        </td>
                      </tr>
                      {p.flights.map((f) => (
                        <tr className="flight-row">
                          <td>
                            {f.from.location.cityName}, {f.from.location.country} (
                            {f.from.location.iata}) - {f.to.location.cityName},{" "}
                            {f.to.location.country} ({f.to.location.iata})<br />
                            {f.carrier}-{f.number}
                            <br />
                            Marketing carrier: {f.marketingCarrierName}
                            {f.carrier === f.operatingCarrier ? (
                              <></>
                            ) : (
                              <>
                                <br />
                                Operating carrier: {f.operatingCarrierName}
                              </>
                            )}
                          </td>
                          <td>
                            {formatDate(f.from.date)}, {f.from.time}
                            <br />
                            Airport: {f.from.location.airportName}
                            {f.from.location.terminal === "" ? (
                              <></>
                            ) : (
                              <>
                                <br />
                                Terminal: {f.from.location.terminal}
                              </>
                            )}
                          </td>
                          <td>
                            {formatDate(f.to.date)}, {f.to.time}
                            <br />
                            Airport: {f.to.location.airportName}
                            {f.to.location.terminal === "" ? (
                              <></>
                            ) : (
                              <>
                                <br />
                                Terminal: {f.to.location.terminal}
                              </>
                            )}
                          </td>
                          <td>
                            Duration: {f.duration}
                            <br />
                            Bags: {f.bags}
                          </td>
                          <td>Segment: {f.segment}</td>
                        </tr>
                      ))}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  renderPnrs() {
    return (
      <Tabs>
        {this.state.data.pnrs.map((p, index) => (
          <Tab
            id={`pnr${index}`}
            title={`Farecloud: ${p.farecloudId}`}
            panel={this.renderPnr(p, index)}
          />
        ))}
      </Tabs>
    );
  }

  renderPnr(p, index) {
    if (this.state.pnrsLoading[index] !== true && !this.state.pnrs[index]) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;

      const { pnrsLoading } = this.state;
      pnrsLoading[index] = true;

      this.setState({
        pnrsLoading,
      });

      axios
        .get(`${config.apiHost}pnr/${p.id}`)
        .then((response) => {
          const { pnrs } = self.state;
          pnrs[index] = response.data.data;

          const { pnrsLoading } = self.state;
          pnrsLoading[index] = false;

          self.setState({
            pnrs,
            pnrsLoading,
          });
        })
        .catch((error) => {
          self.selfAlert(`Error loading PNR#${index}: ${error}`);
        });
    }

    return (
      <div className="pnr-block">
        <div className="pnr-data">
          {this.state.pnrs[index] ? (
            <ReactJson
              displayDataTypes={false}
              displayObjectSize={false}
              theme="monokai"
              src={
                typeof this.state.pnrs[index] === "object"
                  ? this.state.pnrs[index]
                  : JSON.parse(this.state.pnrs[index])
              }
            />
          ) : (
            <Spinner intent="primary" size={30} />
          )}
        </div>
      </div>
    );
  }

  forwardEmail = (id) => {
    this.setState({
      loading: true,
    });

    return (
      axios
        .get(`${config.apiHost}email/incoming/${id}/forward`)
        .then(() => {
          this.loadData();
          this.selfAlert("Email forwarded");
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          const errorMessage = this.handleAxiosCatch(error);
          this.loadData();
        })
    );
  };

  renderEmails() {
    return (
      <Tabs>
        <Tab id="t2" title="Sent emails" panel={this.renderSentEmails()} />
        <Tab id="t1" title="Incoming emails" panel={this.renderIncomingEmails()} />
      </Tabs>
    );
  }

  renderIncomingEmails() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel">
            <div className="panel-heading">
              <div>Incoming emails</div>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <th>PNR</th>
                  <th>Date</th>
                  <th>Subject</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Attachments</th>
                  <th />
                </tr>
                {this.state.data.incomingEmails.map((e) => (
                  <tr>
                    <td>{e.pnrNumber}</td>
                    <td>{e.date}</td>
                    <td>{e.subject}</td>
                    <td>{e.sender}</td>
                    <td>{e.recipient}</td>
                    <td>
                      {e.files.map((f) => (
                        <a href={`${config.apiHost}email/file/${f.id}?key=${f.key}`}>{f.name}</a>
                      ))}
                    </td>
                    <td>
                      {e.html ? (
                        <ControlledModal
                          button={<Button small>HTML</Button>}
                          title="Email HTML"
                          dialogProps={{ className: "booking-email email-html" }}
                        >
                          <iframe
                            title="Incoming email"
                            src={`${config.apiHost}email/incoming/${e.id}/html?key=${e.key}`}
                          />
                        </ControlledModal>
                      ) : (
                        <></>
                      )}
                      {e.text ? (
                        <ControlledModal
                          button={<Button small>Text</Button>}
                          title="Email Text"
                          dialogProps={{ className: "booking-email email-text" }}
                        >
                          <TextArea fill value={e.text} readOnly />
                        </ControlledModal>
                      ) : (
                        <></>
                      )}
                      <Button
                        disabled={this.state.loading}
                        small
                        onClick={() => this.forwardEmail(e.id)}
                      >
                        Forward
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  renderSentEmails() {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel">
            <div className="panel-heading">
              <div>Sent emails</div>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <th>Id</th>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Subject</th>
                  <th>Mailgun id</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th />
                </tr>
                {this.state.data.sentEmails.map((e) => (
                  <tr>
                    <td>{e.id}</td>
                    <td>{e.date}</td>
                    <td>{e.type}</td>
                    <td>{e.subject}</td>
                    <td>{e.mailgunId}</td>
                    <td>{e.email}</td>
                    <td>
                      {formatLabel(e.status)}
                      {e.error !== "" ? (
                        <>
                          <br />
                          {e.error}
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      {" "}
                      {e.html ? (
                        <ControlledModal
                          button={<Button small>HTML</Button>}
                          title="Email HTML"
                          dialogProps={{ className: "booking-email email-html" }}
                        >
                          <iframe src={`${config.apiHost}email/sent/${e.id}/html?key=${e.key}`} />
                        </ControlledModal>
                      ) : (
                        <></>
                      )}
                      {e.text ? (
                        <ControlledModal
                          button={<Button small>Text</Button>}
                          title="Email Text"
                          dialogProps={{ className: "booking-email email-text" }}
                        >
                          <TextArea fill value={e.text} readOnly />
                        </ControlledModal>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  sendSms = () => {
    const text = prompt("SMS Text", "");
    if (text) {
      this.setState({ loading: true });

      return (
        axios
          .get(`${config.apiHost}booking/${this.getId()}/send-sms`, { params: { text: text } })
          .then(() => {
            this.loadData();
            this.selfAlert("SMS sent");
          })
          // eslint-disable-next-line no-unused-vars
          .catch((error) => {
            const errorMessage = this.handleAxiosCatch(error);
            this.loadData();
          })
      );
    }
  };

  renderSms = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel">
            <div className="panel-heading">
              <div>SMS</div>
              <Button small onClick={this.sendSms}>
                Send new
              </Button>
            </div>
            <table className="table">
              <tbody>
                <tr>
                  <th>Id</th>
                  <th>Provider id</th>
                  <th>Date</th>
                  <th>Recipient</th>
                  <th>Text</th>
                  <th>Status</th>
                </tr>
                {this.state.data.sms.map((e) => (
                  <tr>
                    <td>{e.id}</td>
                    <td>
                      {e.provider}
                      <br />
                      {e.providerId}
                    </td>
                    <td>{e.date}</td>
                    <td>{e.recipient}</td>
                    <td>{e.text}</td>
                    <td>{formatLabel(e.status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  renderLog = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="panel">
            <div className="panel-heading">
              <div>Log</div>
            </div>
            <table className="table log-table">
              <tbody>
                <tr>
                  <th>Id</th>
                  <th>Date</th>
                  <th>Text</th>
                </tr>
                {this.state.data.log.map((e) => (
                  <tr>
                    <td>{e.id}</td>
                    <td className="datetime">
                      {e.date} {e.time}{" "}
                    </td>
                    <td>
                      <p>{e.text}</p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
}

export const BookingPage = withRouter(BookingPageComponent);
