import "../css/login.scss";

import React from "react";
import { Alignment, Button, Card, Navbar } from "@blueprintjs/core";
import { Page } from "./page";
import { isLoggedIn, loginGoogle, setTokens } from "../utils/auth";
import { withRouter } from "react-router";

class LoginPageComponent extends Page {
  render() {
    const url = new URL(window.location.href);

    if (url.searchParams.get("token")) {
      setTokens(url.searchParams.get("token"), url.searchParams.get("name"));

      this.props.history.push("/bookings");
      return null;
    }

    if (isLoggedIn()) {
      this.props.history.push("/bookings");
      return null;
    }

    return (
      <div className="LoginPage">
        <Navbar className="bp3-dark">
          <div className="container">
            <Navbar.Group align={Alignment.LEFT}>
              <Navbar.Heading>JetCompass</Navbar.Heading>
            </Navbar.Group>
          </div>
        </Navbar>
        <div className="section login-section">
          <div className="container text-center">
            <Card>
              <p>Please sign in to proceed</p>
              <Button
                rightIcon="arrow-right"
                intent="success"
                text="Sign in with Google"
                onClick={loginGoogle}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export const LoginPage = withRouter(LoginPageComponent);
