import { Component } from "react";
import { Intent } from "@blueprintjs/core";
import { logout } from "../utils/auth";
import * as _ from "lodash";

class MyComponent extends Component {
  handleAxiosCatch = (error) => {
    if (error.response != null && error.response.status === 401) {
      logout();
    }

    if (error?.response?.data?.error) {
      this.selfAlert(error.response.data.error, Intent.DANGER);
      return error.response.data.error;
    }

    this.selfAlert(error.message, Intent.DANGER);
    return error.message;
  };

  selfAlert(text, intent = Intent.PRIMARY) {
    alert(text);
  }

  setStateValue(paramPath, value) {
    const state = this.state;
    _.set(state, paramPath, value);
    this.setState(state);
  }
}

export default MyComponent;
