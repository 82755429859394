import React, { Component } from "react";
import { Button, ControlGroup, FormGroup, HTMLSelect, InputGroup, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import config from "../config";
import ControlledModal from "../controlled_modal";

class RefundPaymentModal extends Component {
  state = {
    currency: "EUR",
    amount: "",
    description: "",
  };

  refundPayment = () => {
    let amount = null;
    try {
      amount = parseFloat(this.state.amount);
    } catch (e) {}

    if (!amount || Number.isNaN(amount)) {
      alert(`Invalid amount - ${this.state.amount}`);
      return false;
    }

    this.setState({
      currency: "EUR",
      amount: "",
      description: "",
    });

    this.props.onRefund(this.props.paymentId, amount, this.state.currency, this.state.description);

    return true;
  };

  render() {
    return (
      <ControlledModal
        button={
          <Button small intent={Intent.DANGER}>
            Refund
          </Button>
        }
        title="Refund payment"
        okButton={<Button intent={Intent.PRIMARY}>Refund payment</Button>}
        okAction={this.refundPayment}
      >
        <p>
          <FormGroup
            label="Refund amount"
            labelFor="amount-input"
            labelInfo={`of ${this.props.amountTotal}`}
          >
            <ControlGroup fill vertical={false}>
              <InputGroup
                id="amount-input"
                placeholder="Amount"
                value={this.state.amount}
                onChange={(e) => {
                  this.setState({ amount: e.target.value });
                }}
              />
              <HTMLSelect
                options={config.currencies}
                value={this.state.currency}
                onChange={(e) => {
                  this.setState({
                    currency: e.target.value,
                  });
                }}
              />
            </ControlGroup>
          </FormGroup>
        </p>
        <p>
          <FormGroup label="Refund description" labelFor="refund-input">
            <InputGroup
              id="refund-input"
              placeholder="Description"
              value={this.state.description}
              onChange={(e) => {
                this.setState({ description: e.target.value });
              }}
            />
          </FormGroup>
        </p>
      </ControlledModal>
    );
  }
}

RefundPaymentModal.propTypes = {
  onRefund: PropTypes.func,
  amountTotal: PropTypes.string,
  paymentId: PropTypes.number,
};

RefundPaymentModal.defaultProps = {
  onRefund: null,
  amountTotal: "",
  paymentId: "",
};

export default RefundPaymentModal;
