import "../css/menu.scss";

import React from "react";
import { Alignment, Button, InputGroup, Menu, MenuItem, Navbar, Popover } from "@blueprintjs/core";
import axios from "axios";
import { logout } from "../utils/auth";
import MyComponent from "./my_component";
import config from "./config";
import { withRouter } from "react-router";

class TopMenuComponent extends MyComponent {
  state = {
    activeItem: this.props.history.location.pathname.substring(1),
    search: "",
    isSearching: false,
  };

  handleItemClick(name) {
    let history = this.props.history;
    return history.push(`/${name}`);
  }

  search = () => {
    this.setState({ isSearching: true });

    return axios
      .get(`${config.apiHost}bookings/search`, {
        params: { q: this.state.search },
      })
      .then((response) => {
        this.setState({ isSearching: false });
        if (response?.data?.error) {
          this.selfAlert(response.data.error);
          return;
        }

        const { id } = response.data;
        this.props.history.push(`/booking/${id}`);
      })
      .catch((error) => {
        this.setState({ isSearching: false });

        // eslint-disable-next-line no-unused-vars
        const errorMessage = this.handleAxiosCatch(error);
      });
  };

  render() {
    const { activeItem } = this.state;

    return (
      <Navbar className="bp3-dark">
        <div className="container">
          <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>JetCompass</Navbar.Heading>
            <Navbar.Divider />
            <InputGroup
              leftIcon="search"
              placeholder="Find booking"
              value={this.state.search}
              onChange={(e) => {
                this.setState({ search: e.target.value });
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  this.search();
                }
              }}
              rightElement={
                <Button
                  className="bp3-minimal"
                  icon="arrow-right"
                  disabled={this.state.isSearching}
                  onClick={this.search}
                  intent="primary"
                />
              }
            />
          </Navbar.Group>
          <Navbar.Group align={Alignment.RIGHT} className="space">
            <Button
              className="bp3-minimal"
              text="Bookings"
              active={activeItem === "bookings"}
              onClick={() => this.handleItemClick("bookings")}
            />

            <Popover
              className="bp3-dark"
              content={
                <Menu>
                  <MenuItem
                    text="Settings"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("config/settings")}
                  />
                  <MenuItem
                    text="Carriers"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("config/carriers")}
                  />
                  <MenuItem
                    text="Users"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("config/users")}
                  />
                  <MenuItem
                    text="Partners"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("config/partners")}
                  />
                  <MenuItem
                    text="Script"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("config/script")}
                  />
                </Menu>
              }
            >
              <Button
                className="bp3-minimal"
                text="Config"
                active={activeItem === "config"}
                rightIcon="caret-down"
              />
            </Popover>

            <Button
              className="bp3-minimal"
              text="Tasks"
              active={activeItem === "tasks"}
              onClick={() => this.handleItemClick("tasks")}
            />

            <Popover
              className="bp3-dark"
              content={
                <Menu>
                  <MenuItem
                    text="Incoming emails"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("emails/incoming")}
                  />
                  <MenuItem
                    text="Send email"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("emails/send")}
                  />
                </Menu>
              }
            >
              <Button className="bp3-minimal" text="Emails" rightIcon="caret-down" />
            </Popover>

            <Popover
              className="bp3-dark"
              content={
                <Menu>
                  <MenuItem
                    text="Daily report"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("report/daily")}
                  />
                  <MenuItem
                    text="Charges report"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("report/chages")}
                  />
                  <MenuItem
                    text="Flags report"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("report/flags")}
                  />
                  <MenuItem
                    text="Unticketed report"
                    className="bp3-minimal"
                    onClick={() => this.handleItemClick("report/unticketed")}
                  />
                </Menu>
              }
            >
              <Button className="bp3-minimal" text="Reports" rightIcon="caret-down" />
            </Popover>

            <Navbar.Divider />

            <Button className="bp3-minimal" icon="log-out" text="Logout" onClick={logout} />
          </Navbar.Group>
        </div>
      </Navbar>
    );
  }
}

export const TopMenu = withRouter(TopMenuComponent);
