import "./css/style.scss";
import { BrowserRouter } from "react-router-dom";
import React, { Component } from "react";
import { checkAuth } from "./utils/auth";
import Routes from "./routes";

export class App extends Component<{}, {}> {
  constructor(props: {}) {
    super(props);
    checkAuth();
  }

  render(): React.ReactNode {
    return (
      <BrowserRouter>
        <div className="fullContainer">
          <div className="bodyContainer">
            <Routes />
          </div>
          {/* <Footer/> */}
        </div>
      </BrowserRouter>
    );
  }
}
