import "../css/emails.scss";

import React from "react";
import { Breadcrumbs, Button, InputGroup, Intent, Spinner, TextArea } from "@blueprintjs/core";
import axios from "axios";
import { Page } from "./page";
import config from "../components/config";
import { withRouter } from "react-router";

class EmailsSendPageComponent extends Page {
  constructor(props) {
    super(props);

    this.state = {
      sql: "SELECT id FROM orders LIMIT 5",
      loading: false,
      sending: false,
      text: "",
      subject: "",
      sendResult: null,

      previewLoading: false,
      previewResult: null,
    };
  }

  getPageName() {
    return "EmailsSendPage";
  }

  renderAuth() {
    const bItems = [{ text: "Home" }, { text: "Send emails" }];

    return (
      <div>
        <Breadcrumbs items={bItems} />
        <div className="toolbar sql-block">
          <h2>SQL query</h2>
          <TextArea
            disabled={this.state.loading}
            fill
            growVertically
            value={this.state.sql}
            onChange={(event) => {
              this.setState({ sql: event.target.value });
            }}
          />
          <Button disabled={this.state.loading} onClick={() => this.checkSql()}>
            Check SQL
          </Button>
          {this.state.loading && <Spinner />}
          {this.state.data && this.renderTable(this.state.data)}
        </div>

        <div className="toolbar message-block">
          <h2>Message</h2>
          <InputGroup
            disabled={this.state.sending}
            value={this.state.subject}
            onChange={(event) => {
              this.setState({ subject: event.target.value });
            }}
            placeholder="Email subject..."
          />
          <TextArea
            disabled={this.state.sending}
            value={this.state.text}
            fill
            growVertically
            placeholder="Text..."
            onChange={(event) => {
              this.setState({ text: event.target.value });
            }}
          />

          <Button disabled={this.state.previewLoading} onClick={() => this.preview()}>
            Preview
          </Button>
          {this.state.previewLoading && <Spinner />}
          {this.state.previewResult && (
            <div className="send-result">
              <code>
                <strong>{this.state.previewResult.subject}</strong>
                <br />
                {this.state.previewResult.text}
              </code>
            </div>
          )}

          <Button
            intent="danger"
            disabled={!this.state.previewResult && this.state.sending}
            onClick={() => this.send()}
          >
            Send message
          </Button>
          {this.state.sending && <Spinner />}
          {this.state.sendResult && (
            <div className="send-result">
              <code>{this.state.sendResult}</code>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderTableCell(object) {
    return Object.values(object).map((data, index) => {
      return <td key={index}>{data}</td>;
    });
  }

  tableRows(data) {
    return data.map((value) => {
      return <tr key={value.index}>{this.renderTableCell(value)}</tr>;
    });
  }

  renderTable(data) {
    return (
      <table className="bp3-html-table bp3-html-table-bordered bp3-html-table-striped">
        <thead>
          <tr>
            {Object.keys(data[0]).map((value, index) => {
              return <th key={index}>{value}</th>;
            })}
          </tr>
        </thead>
        <tbody>{this.tableRows(data)}</tbody>
      </table>
    );
  }

  preview = () => {
    this.setState({ previewLoading: true });

    return axios
      .post(`${config.apiHost}emails/preview`, {
        sql: this.state.sql,
        subject: this.state.subject,
        text: this.state.text,
      })
      .then((response) => {
        if (response?.data?.error) {
          this.selfAlert(response.data.error, Intent.DANGER);

          return this.setState({
            error: response.data.error,
            previewLoading: false,
            previewResult: null,
          });
        }

        return this.setState({
          error: null,
          previewLoading: false,
          previewResult: response.data.data,
        });
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);

        this.setState({
          error: errorMessage,
          previewLoading: false,
          previewResult: null,
        });
      });
  };

  send = () => {
    this.setState({ sending: true });

    return axios
      .post(`${config.apiHost}emails/send`, {
        sql: this.state.sql,
        subject: this.state.subject,
        text: this.state.text,
      })
      .then((response) => {
        if (response?.data?.error) {
          this.selfAlert(response.data.error, Intent.DANGER);

          return this.setState({
            error: response.data.error,
            sending: false,
            sendResult: null,
          });
        }

        return this.setState({
          error: null,
          sending: false,
          sendResult: response.data.data,
        });
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);

        this.setState({
          error: errorMessage,
          sending: false,
          sendResult: null,
        });
      });
  };

  checkSql = () => {
    this.setState({ loading: true });

    return axios
      .post(`${config.apiHost}emails/sql`, {
        sql: this.state.sql,
      })
      .then((response) => {
        if (response?.data?.error) {
          this.selfAlert(response.data.error, Intent.DANGER);

          return this.setState({
            error: response.data.error,
            loading: false,
            data: [],
          });
        }

        return this.setState({
          error: null,
          loading: false,
          data: response.data.data,
        });
      })
      .catch((error) => {
        const errorMessage = this.handleAxiosCatch(error);

        this.setState({
          error: errorMessage,
          loaded: true,
          loading: false,
        });
      });
  };
}

export const EmailsSendPage = withRouter(EmailsSendPageComponent);
