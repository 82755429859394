import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import { LoginPage } from "./pages/login";
import { BookingsPage } from "./pages/bookings";
import { TasksPage } from "./pages/tasks";
import { EmailsSendPage } from "./pages/emails_send";
import { BookingPage } from "./pages/booking";

export const Routes = (props: { [key: string]: never } = {}) => (
  <Switch {...props}>
    <Route path="/orders" exact component={BookingsPage} />
    <Route path="/bookings" exact component={BookingsPage} />
    <Route path="/tasks" exact component={TasksPage} />
    {/* <Route path="/partners" exact component={PartnersPage} /> */}
    {/* <Route path="/balances" exact component={BalancesPage} /> */}
    {/* <Route path="/tasks" exact component={TasksPage} /> */}
    {/* <Route path="/cache" exact component={CachePage} /> */}
    {/* <Route path="/suppliers" exact component={SuppliersPage} /> */}
    <Route path="/order/:id" component={BookingPage} />
    <Route
      path="/booking/:id"
      render={(routeProps) => {
        console.log(routeProps.match.params.id);
        return <BookingPage {...routeProps} key={routeProps.match.params.id} />;
      }}
    />
    {/* <Route path="/partner/:partner" component={PartnerPage} /> */}

    <Route path="/emails/send" exact component={EmailsSendPage} />

    <Route path="*" exact component={LoginPage} />
  </Switch>
);

export default Routes;
